<template>
  <v-card class="moholand-shadow mx-auto rounded-xl">
    <v-card-text>
      <h2>{{ scenario.title }}</h2>
      <div class="mt-2 scenario-description three-line-text">
        {{ scenario.excerpt }}
      </div>
    </v-card-text>

    <v-divider class="mx-4" />

    <v-card-actions class="px-4">
      <v-btn dark small color="orange" :to="'/scenarios/' + scenario.slug">
        مشاهده
      </v-btn>
      <v-spacer />
      <v-rating
        :value="scenario.rate || 0"
        color="amber"
        dense
        length="5"
        readonly
        size="18"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    scenario: {
      type: Object,
    },
    media: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.video-parent {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden !important;
}
.play-button {
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  position: absolute;
}
.scenario-description {
  min-height: 100px;
}
.three-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
