<template>
  <v-card class="moholand-shadow mx-auto rounded-xl">
    <div class="video-parent" :class="{ grey: !media }">
      <video width="100%" v-if="media">
        <source :src="media.url + '#t=0.5'" type="video/mp4" />
      </video>
      <v-btn
        icon
        class="rounded-0 play-button"
        @click="media && $emit('onShowVideo', media)"
      >
        <v-icon color="white" size="100">
          mdi-play-circle-outline
        </v-icon>
      </v-btn>
    </div>

    <v-card-text>
      {{ title }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    media: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.video-parent {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden !important;
}
.play-button {
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  position: absolute;
}
</style>
