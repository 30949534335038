<template>
  <v-card
    class="moholand-shadow mx-auto rounded-xl overflow-hidden fill-height"
    max-width="300"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-card-title class="product-title pb-0 black--text">
      {{ announcer.title }}
    </v-card-title>

    <v-card-text class="pa-0 pt-1">
      <audio-player
        ref="audioplayer"
        flat
        :src="announcer.voice ? announcer.voice.url : null"
        :index="index"
        @onPlay="setPlayIndex"
      />

      <v-tooltip bottom color="black" v-if="withUser && announcer.user">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            class="rounded-lg mr-3"
            color="orange"
            dark
            text
            small
            v-if="announcer.user"
            v-bind="attrs"
            v-on="on"
            :disabled="disabledReference"
            @click="$router.push('/sellers/' + announcer.user.id)"
          >
            <v-avatar
              size="30"
              style="border-radius: 10px;border: 2px solid #f66200"
              class="justify-center"
            >
              <v-img
                :src="
                  announcer.user.avatar_url || require('@/assets/img/man.jpg')
                "
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      v-if="announcer.user.avatar_url"
                      indeterminate
                      size="small"
                      color="grey lighten-2"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-btn>
        </template>
        <span>
          {{ announcer.user.first_name + " " + announcer.user.last_name }}
        </span>
      </v-tooltip>
    </v-card-text>

    <v-card-actions class="text-center">
      <!-- <v-rating
        v-if="product"
        :value="product.rate"
        color="amber"
        dense
        readonly
        length="5"
        size="18"
        class="mx-auto"
      /> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import { getUnixDifferenceTimeFromNow } from "@/constants/TimeFunctions";
import AudioPlayer from "../AudioPlayer.vue";
export default {
  components: { AudioPlayer },
  props: {
    announcer: {
      type: Object,
    },
    playIndex: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    withUser: {
      type: Boolean,
      default: true,
    },
    disabledReference: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    playIndex: {
      immediate: true,
      handler() {
        if (this.$refs?.audioplayer && this.playIndex != this.index)
          this.$refs?.audioplayer?.stop();
      },
    },
  },
  methods: {
    setPlayIndex() {
      this.$emit("setPlayIndex", this.index);
    },
  },
};
</script>

<style scoped>
.product-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
</style>
