<template>
  <v-container>
    <v-card class="rounded-xl moholand-shadow">
      <v-card-text>
        <template v-if="seller.desc">
          <h2 class="orange--text">درباره من</h2>
          <div class="mt-3">{{ seller.desc }}</div>
        </template>
        <div class="d-flex flex-wrap mt-3 px-4">
          <v-img
            :src="require('../../../assets/icons/calendar.png')"
            class="grey-icon icon-extra-small"
          />
          <div class="my-auto mr-2">تاریخ عضویت:</div>
          <div class="my-auto mr-2">
            {{ seller.created_at | moment("jYYYY/jMM/jDD") }}
          </div>
        </div>
        <div class="d-flex flex-wrap mt-4 px-4">
          <v-img
            :src="require('../../../assets/icons/folder.png')"
            class="grey-icon icon-extra-small"
          />
          <div class="my-auto mr-2">
            {{ products_count + " فایل" }}
          </div>
        </div>
        <div class="d-flex flex-wrap mt-4 px-4">
          <v-img
            :src="require('../../../assets/icons/inbox.png')"
            class="grey-icon icon-extra-small"
          />
          <div class="my-auto mr-2">
            {{ sell_count + " فروش" }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MyPaginate from "../../MyPaginate.vue";
import TitleHeading from "../../TitleHeading.vue";
import ProductBox from "../ProductBox.vue";
export default {
  components: { ProductBox, MyPaginate, TitleHeading },
  props: {
    seller: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    products_count: {
      type: Number | String,
    },
    sell_count: {
      type: Number | String,
    },
  },
};
</script>

<style></style>
