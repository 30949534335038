<template>
  <v-card
    class="moholand-shadow mx-auto rounded-lg"
    @click="$router.push('/courses/' + item.slug)"
  >
    <v-img
      height="200"
      :src="item.media ? item.media.url : require('@/assets/img/thumb.jpg')"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            v-if="item.media"
            indeterminate
            color="grey lighten-2"
          ></v-progress-circular>
          <v-btn v-else text disabled>
            بدون تصویر
          </v-btn>
        </v-row>
      </template>
    </v-img>

    <v-card-title class="course-title pb-0">
      {{ item.name || item.title }}
    </v-card-title>

    <v-card-actions class="">
      <v-row no-gutters align="center">
        <v-col cols="12" sm="6">
          <v-chip class="rounded-lg" text-color="white" label color="green">
            <!-- {{ item.created_at | moment("jYYYY/jMM/jDD") }} -->
            سطح
            {{ categories[item.level || 1] }}
          </v-chip>
        </v-col>
        <v-col cols="12" sm="6">
          <v-rating
            :value="item.rate"
            length="5"
            color="amber"
            dense
            readonly
            size="20"
          ></v-rating>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Constants } from "../constants/GlobalConstants";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      categories: Constants.coursesCategories,
    };
  },
};
</script>

<style scoped>
.course-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
