<template>
  <v-container fluid class="grey lighten-3 pt-6 pa-0">
    <contact-seller ref="contactSeller" :vendor_id="id" />
    <report-seller ref="reportSeller" :vendor_id="id" />
    <dialog-video v-model="videoDialog" :videoURLS="videoURLS" />

    <v-img max-height="20vh" :src="require('@/assets/login-bg.jpg')" />
    <div class="white moholand-shadow">
      <v-container>
        <v-row align="center">
          <v-col md="6" cols="12">
            <v-row
              no-gutters
              :justify="$vuetify.breakpoint.mdAndUp ? 'flex-end' : 'center'"
            >
              <div class="text-center">
                <v-img
                  :src="
                    seller.avatar_url || require('../../assets/img/man.jpg')
                  "
                  class="product-img rounded-xl orange darken-1 mt-n10"
                />
              </div>
              <div class="mr-2">
                <h2 class="mt-4" v-if="store">
                  {{ store.store_name }}
                </h2>
                <small>
                  ({{
                    seller.first_name
                      ? seller.first_name + " " + seller.last_name
                      : "-"
                  }})
                </small>
                <div>
                  <strong>
                    {{ followersCount }}
                  </strong>
                  <small>دنبال کننده </small>
                </div>
              </div>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col sm cols="5">
            <v-btn
              block
              class="rounded-lg"
              color="orange darken-2"
              large
              dark
              @click="onSendMessage()"
            >
              ارسال پیام
            </v-btn>
          </v-col>
          <template
            v-if="
              $store.state.userData &&
                $store.state.userData.id &&
                $store.state.userData.id != seller.id
            "
          >
            <v-col sm cols="6" v-if="isFollowed">
              <v-btn
                block
                outlined
                class="rounded-lg"
                color="success"
                large
                dark
                :loading="followerLoading"
                @click="unfollow"
              >
                دنبال میکنید
              </v-btn>
            </v-col>
            <v-col sm cols="6" v-else>
              <v-btn
                block
                class="rounded-lg"
                color="success"
                large
                dark
                :loading="followerLoading"
                @click="follow"
              >
                دنبال کن
              </v-btn>
            </v-col>
          </template>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loading"
                text
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$refs.reportSeller.onOpen()">
                <v-list-item-title>گزارش تخلف</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-divider class="mt-2" />
        <v-tabs v-model="tab" color="orange darken-2" show-arrows>
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-if="products_count">فایل‌ها</v-tab>
          <v-tab v-if="courses_count">دوره‌ها</v-tab>
          <v-tab v-if="portfolios_count">انیمیشن‌ها</v-tab>
          <v-tab v-if="podcasts_count">گویندگی‌ها</v-tab>
          <v-tab v-if="scenarios_count">داستان‌ها</v-tab>
          <v-tab>پروفایل</v-tab>
        </v-tabs>
      </v-container>
    </div>
    <v-tabs-items v-model="tab" class="transparent mt-1">
      <v-tab-item v-if="products_count">
        <products
          :items="products"
          :loading="loading"
          :tab="tab"
          tabnumber="0"
        />
      </v-tab-item>
      <v-tab-item v-if="courses_count">
        <courses :tab="tab" tabnumber="1" :seller="seller" />
      </v-tab-item>
      <v-tab-item v-if="portfolios_count">
        <animations :tab="tab" tabnumber="2" :seller="seller" />
      </v-tab-item>
      <v-tab-item v-if="podcasts_count">
        <voices :tab="tab" tabnumber="3" :seller="seller" />
      </v-tab-item>
      <v-tab-item v-if="scenarios_count">
        <scenarios :tab="tab" tabnumber="4" :seller="seller" />
      </v-tab-item>
      <v-tab-item>
        <profile
          :seller="seller"
          :sell_count="sell_count"
          :products_count="products_count"
          :loading="loading"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import DialogVideo from "../../components/DialogVideo.vue";
import MyPaginate from "../../components/MyPaginate.vue";
import MySwiper from "../../components/MySwiper.vue";
import CartButton from "../../components/Pages/CartButton.vue";
import ContactSeller from "../../components/Pages/ContactSeller.vue";
import MomentsOffer from "../../components/Pages/MomentsOffer.vue";
import ProductBox from "../../components/Pages/ProductBox.vue";
import ReportSeller from "../../components/Pages/ReportSeller.vue";
import WorkItem from "../../components/Pages/WorkItem.vue";
import TitleHeading from "../../components/TitleHeading.vue";
import MyAxios from "../../constants/MyAxios";

import Products from "../../components/Pages/SellerPage/Products.vue";
import Animations from "../../components/Pages/SellerPage/Animations.vue";
import Profile from "../../components/Pages/SellerPage/Profile.vue";
import Scenarios from "../../components/Pages/SellerPage/Scenarios.vue";
import Voices from "../../components/Pages/SellerPage/Voices.vue";
import Courses from "../../components/Pages/SellerPage/Courses.vue";

export default {
  components: {
    MyPaginate,
    ProductBox,
    CartButton,
    MomentsOffer,
    TitleHeading,
    MySwiper,
    ContactSeller,
    ReportSeller,
    WorkItem,
    DialogVideo,
    Products,
    Animations,
    Profile,
    Scenarios,
    Voices,
    Courses,
  },
  data() {
    return {
      id: this.$route.params.id || "",
      isFollowed: false,
      followersCount: 0,
      followerLoading: false,
      search: "",
      tab: null,
      seller: {},
      store: {},
      portfolios: [],
      products: {
        data: [],
        last_page: 1,
      },
      courses: {
        data: [],
        last_page: 1,
      },
      voices: {
        data: [],
        last_page: 1,
      },
      animations: {
        data: [],
        last_page: 1,
      },
      scenarios: {
        data: [],
        last_page: 1,
      },
      videoDialog: false,
      videoURLS: [],
      loading: false,
      page: 1,
      pagesLength: 1,
      path: null,
      total: null,
      products_count: 0,
      sell_count: 0,
      courses_count: 0,
      podcasts_count: 0,
      portfolios_count: 0,
      scenarios_count: 0,
    };
  },
  mounted() {
    this.GetData();
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query?.page || 1;
      this.GetData();
    },
  },
  methods: {
    GetData: function() {
      this.$root.$emit("globalLoadingSet", true);
      MyAxios.get("/sellers/data/user/" + this.id, {
        params: {
          conditions: {
            status: 1,
          },
          page: this.page,
        },
      })
        .then((response) => {
          this.products = response.data?.products || {
            data: [],
            last_page: 1,
          };
          // this.courses = response.data?.courses || {
          //   data: [],
          //   last_page: 1,
          // };
          // this.voices = response.data?.voices || {
          //   data: [],
          //   last_page: 1,
          // };
          // this.scenarios = response.data?.scenarios || {
          //   data: [],
          //   last_page: 1,
          // };
          // this.animations = response.data?.animations || {
          //   data: [],
          //   last_page: 1,
          // };
          this.seller = response.data.seller;
          this.store = response.data.store;
          this.sell_count = response.data?.sell_count || 0;
          this.courses_count = response.data?.courses_count || 0;
          this.podcasts_count = response.data?.podcasts_count || 0;
          this.portfolios_count = response.data?.portfolios_count || 0;
          this.products_count = response.data.products_count || 0;
          this.scenarios_count = response.data.scenarios_count || 0;
          this.portfolios = response.data.portfolios;
          this.isFollowed = !!response.data?.seller?.is_followed;
          this.followersCount = response.data?.seller?.followers_count || 0;
          // this.tab = 1;
          this.$root.$emit("globalLoadingSet", false);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    follow() {
      if (!this.followerLoading) this.followerLoading = true;

      MyAxios.post("followers/create", {
        user_id: this.seller?.id,
        follower_id: this.$store.state.userData?.id,
      })
        .then((response) => {
          console.log(response);
          this.followersCount += 1;
          this.isFollowed = true;
          this.followerLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.followerLoading = false;
        });
    },
    unfollow() {
      if (!this.followerLoading) this.followerLoading = true;

      MyAxios.get("followers/unFollow/" + this.seller?.id)
        .then((response) => {
          console.log(response);
          if (this.followersCount > 0) this.followersCount -= 1;
          this.isFollowed = false;
          this.followerLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.followerLoading = false;
        });
    },
    onSubmit(search) {
      this.search = search;
      this.$router.push({
        name: "SellersList",
        query: {
          name: search,
        },
      });
      this.GetData();
    },
    playVideo(media) {
      if (media.url) {
        this.videoURLS = [
          {
            src: media.url || "",
            // src: item.video.url,
            type: "video/mp4",
          },
        ];
        this.videoDialog = true;
      }
    },
    onSendMessage() {
      if (this.$store?.state?.userData?.id) this.$refs.contactSeller.onOpen();
      else
        this.$root.$emit("toast", {
          text: "برای ارسال پیام وارد حساب کاربری خود شوید",
        });
    },
  },
};
</script>

<style scoped>
.simple-header {
  width: 100%;
  background: url("../../assets/header.jpg");
}

.product-img {
  border: 5px solid;
  height: 150px;
  width: 150px;
}

.v-tab {
  letter-spacing: 0 !important;
}
</style>
