<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="modal" width="600px">
      <v-card class="text-center rounded-lg">
        <v-card-text>
          <v-form ref="form" @submit.prevent="submit">
            <v-row justify="center">
              <v-col md="9" cols="12">
                <h1 class="orange--text darken-1 my-8">
                  ارسال پیام
                </h1>
                <v-form-base
                  :row="rowAttribute"
                  :col="{ cols: 12 }"
                  :model="model"
                  :schema="schema"
                />
                <v-btn
                  block
                  large
                  dark
                  color="success"
                  :loading="loading"
                  type="submit"
                >
                  ارسال درخواست شما
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Constants } from "../../constants/GlobalConstants";
import VFormBase from "vuetify-form-base";
import MyAxios from "../../constants/MyAxios";
import { formPostPrepare } from "@/constants/PanelFormHandler";

export default {
  components: { VFormBase },
  props: {
    vendor_id: {
      type: String | Number,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      loading: false,
      rules: Constants.rules,
      model: {
        email: "",
        cellphone: "",
        name: "",
        desc: "",
      },
      schema: {
        // name: {
        //   type: "text",
        //   solo: true,
        //   flat: true,
        //   label: "نام شما...",
        //   required: true,
        //   class: "required rounded-lg",
        //   backgroundColor: "grey lighten-2",
        //   rules: [Constants.rules.required, Constants.rules.persian_string],
        // },
        // cellphone: {
        //   type: "text",
        //   solo: true,
        //   flat: true,
        //   label: "موبایل شما...",
        //   required: true,
        //   class: "required rounded-lg",
        //   backgroundColor: "grey lighten-2",
        //   rules: [Constants.rules.cellphone],
        // },
        // email: {
        //   type: "text",
        //   solo: true,
        //   flat: true,
        //   label: "ایمیل شما...",
        //   backgroundColor: "grey lighten-2",
        //   rules: [Constants.rules.required, Constants.rules.email],
        //   class: "required rounded-lg",
        //   // col: 9,
        // },
        desc: {
          type: "textarea",
          solo: true,
          flat: true,
          label: "متن پیام...",
          backgroundColor: "grey lighten-2",
          rules: [Constants.rules.required],
          class: "required rounded-lg",
        },
      },
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);
        data.append("user_id", this.$store.state.userData.id);
        data.append("vendor_id", this.vendor_id);

        MyAxios.post("/contacts/create", data)
          .then((response) => {
            this.loading = false;
            this.modal = false;
            this.$root.$emit("toast", {
              text:
                "با موفقیت ثبت شد. نتیجه بررسی به ایمیل شما ارسال خواهد شد.",
              type: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text:
                error.response.data.msg ||
                error.response.data ||
                "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
              type: "error",
            });
          });
      }
    },
    onOpen() {
      this.modal = true;
    },
    onClose() {
      this.modal = false;
    },
  },
};
</script>

<style scoped>
.ico-shadow {
  box-shadow: 0 0 0px 7px #cbcbcb, 0 0 4px 13px #dcdcdc;
}
</style>
