<template>
  <v-container>
    <v-card class="rounded-xl moholand-shadow list-min-height">
      <v-card-text>
        <title-heading title="فایل های فروشنده" />
        <v-row class="mt-4" v-if="loading">
          <v-col sm="6" md="4" lg="3" cols="12" v-for="i in 8" :key="i">
            <v-skeleton-loader type="card" />
          </v-col>
        </v-row>
        <v-row class="mt-4" v-else>
          <v-col
            sm="6"
            md="4"
            lg="3"
            cols="12"
            v-for="(product, i) in products"
            :key="i"
          >
            <product-box
              :product="product"
              :name="product.name"
              :img="product.media ? product.media.url : ''"
              :price="product.offer_price ? product.offer_price : product.price"
              :old-price="product.offer_price && product.price"
              :url="'/products/' + product.slug"
            />
          </v-col>
        </v-row>
        <div v-if="!products.length && !loading" class="text-center mt-8 text-medium">
          هیچ محصولی ثبت نشده است!
        </div>
        <my-paginate v-model="page" :length="last_page" @onChange="GetData" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MyPaginate from "../../MyPaginate.vue";
import TitleHeading from "../../TitleHeading.vue";
import ProductBox from "../ProductBox.vue";
export default {
  components: { ProductBox, MyPaginate, TitleHeading },
  data() {
    return {
      products: [],
      page: 1,
      last_page: 1,
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
      required: true,
    },
    tabnumber: {
      type: Number,
      required: true,
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(val) {
        this.products = val?.data || [];
        this.last_page = val?.last_page || 1;
      },
    },
  },
  methods: {
    GetData(page) {
      this.$router.replace({
        path: "/sellers/" + this.$route.params.id,
        query: {
          ...this.$route.query,
          page,
        },
      });
    },
  },
};
</script>

<style></style>
