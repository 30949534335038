<template>
  <v-container>
    <v-card class="rounded-xl moholand-shadow list-min-height">
      <v-card-text>
        <title-heading title="داستان ها" />
        <v-row class="mt-4" v-if="loading">
          <v-col sm="6" md="4" cols="12" v-for="i in 8" :key="i">
            <v-skeleton-loader type="card" />
          </v-col>
        </v-row>
        <v-row class="mt-4" v-else>
          <v-col
            sm="6"
            md="4"
            cols="12"
            v-for="(scenario, i) in items"
            :key="i"
          >
            <scenario-item :scenario="scenario" />
          </v-col>
        </v-row>
        <div
          v-if="!items.length && !loading"
          class="text-center mt-8 text-medium"
        >
          هیچ داستان ثبت نشده است!
        </div>
        <my-paginate v-model="page" :length="pagesLength" @onChange="GetData" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import MyAxios from "../../../constants/MyAxios";
import MyPaginate from "../../MyPaginate.vue";
import TitleHeading from "../../TitleHeading.vue";
import ScenarioItem from "../ScenarioItem.vue";
export default {
  components: { MyPaginate, TitleHeading, ScenarioItem },
  data() {
    return {
      items: [],
      page: 1,
      last_page: 1,
      loading: false,
      mounted: false,
    };
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
    tabnumber: {
      type: Number,
      required: true,
    },
    seller: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/scenarios/index", {
        params: {
          conditions: {
            status: 1,
            user_id: this.seller.id,
          },
          page: this.page,
        },
      })
        .then((response) => {
          this.items = response.data?.data || [];
          this.last_page = response.data?.last_page || 1;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
