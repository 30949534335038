<template>
    <div :style="{ color: textColor }" class="parent text-center">
        <div class="d-flex">
            <div class="my-divider d-none d-sm-flex grey lighten-2" />
            <div
                class="moholand-shadow white rounded-lg mx-auto text-medium-small d-flex px-8 py-2"
            >
                {{ title }}
            </div>
            <div class="my-divider d-none d-sm-flex grey lighten-2" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        textColor: {
            type: String,
            default: "#666",
        },
    },
};
</script>

<style scoped>
* {
    direction: rtl;
}
.parent {
    max-width: 300px;
    margin: 0 auto;
    z-index: 100;
}
.sub-heading {
    font-size: 20px;
    color: gray;
}
.heading {
    font-size: 30px;
}
.my-divider {
    display: flex;
    flex: 1;
    height: 3px;
    margin: auto 0;
    border-radius: 2px;
}
</style>
